import cls from 'classnames';
import React, { useRef, useState, Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useMount } from 'react-use';
import { Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';

import { useUserInfo } from '@/stores';
import styles from './index.module.scss';
import Side from '../CenterSide';
import globalContext from '@/configs/globalContext';
import MobileMenu from '../components/MobileMenu';

const UserCenterLayout: React.FC = () => {
  const asideRef = useRef<HTMLDivElement>(null);
  const [asideTop, setAsideTop] = useState(0);
  const { isMobile } = useContext(globalContext);

  useMount(() => {
    setAsideTop((asideRef.current?.getBoundingClientRect().top ?? 0) - 62);
  });

  const { getLoginUserInfo, logout } = useUserInfo();
  const { data: userInfo } = useQuery(
    [getLoginUserInfo.name],
    getLoginUserInfo,
    {
      suspense: true,
    },
  );

  if (!userInfo) {
    logout();
    return null;
  }

  return (
    <section className={cls(styles.userCenterWrap)}>
      <div className={cls('container', styles.userCenter)}>
        {!isMobile && (
          <aside
            ref={asideRef}
            className={cls(styles.userCenterAside)}
            style={{ top: asideTop }}
          >
            <Side />
          </aside>
        )}
        <main className={cls(styles.userCenterMain)}>
          {isMobile && <MobileMenu />}
          <Suspense fallback={<Spin className={styles.userCenterLoading} />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </section>
  );
};

export default UserCenterLayout;
