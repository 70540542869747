// 商城参数
import create from 'zustand';
import { getMallList } from '@/apis';

// 主要驱动来源 url参数也由此驱动更改
export type MallParams = Parameters<typeof getMallList>[0];

// 排序
export enum OrderingEnum {
  // 按最新上架排序
  id = '-id',
  // 按价格高到低排序
  price = '-price',
  // 按价格低到高排序
  '+price' = '+price',
  // 按最热门排序
  sales = '-sales',
  // '按最受喜爱排序'
  num = '-like_num',
}
// interface OrderType {
//   ename: string;
//   locale: string;
// }

// export const orderingMap: Record<OrderingEnum, OrderType> = {
//   [OrderingEnum.id]: {
//     ename: 'Latest arrival',
//     locale: 'mall.new',
//   },
//   [OrderingEnum.price]: {
//     ename: 'Price: high to low',
//     locale: 'mall.hightPrice',
//   },
//   [OrderingEnum['+price']]: {
//     ename: 'Price: low to high',
//     locale: 'mall.lowPrice',
//   },
//   [OrderingEnum.sales]: {
//     ename: 'Best Seller',
//     locale: 'mall.hot',
//   },
//   [OrderingEnum.num]: {
//     ename: 'Most Likes',
//     locale: 'mall.like',
//   },
// };

export const orderingList = [
  {
    label: 'Latest arrival',
    value: OrderingEnum.id,
  },
  {
    label: 'Price: high to low',
    value: OrderingEnum.price,
  },
  {
    label: 'Price: low to high',
    value: OrderingEnum['+price'],
  },
  {
    label: 'Best Seller',
    value: OrderingEnum.sales,
  },
  {
    label: 'Most Likes',
    value: OrderingEnum.num,
  },
];

interface MallParamsStore {
  mallParams?: MallParams;
  // fixPage是否固定page,主要解决filter后page没重置的问题
  updateMallParams: (data: MallParams, fixPage?: boolean) => void;
  resetMallParams: () => void;
}

const defaultParams = {
  page: 1,
  page_size: 50,
  ordering: OrderingEnum.id,
};

export const useMallParams = create<MallParamsStore>((set, get) => ({
  mallParams: defaultParams,
  updateMallParams(data: MallParams, fixPage?: boolean) {
    const oldMallParams = get().mallParams;
    set({
      mallParams: {
        ...oldMallParams,
        ...data,
        page: fixPage
          ? +(data?.page ?? defaultParams.page)
          : defaultParams.page,
        page_size: fixPage
          ? +(data?.page_size ?? defaultParams.page_size)
          : defaultParams.page_size,
      },
    });
  },

  resetMallParams() {
    set({
      mallParams: defaultParams,
    });
  },
}));
