import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cookie from 'js-cookie';

import en from './en';
// import zhCN from './zh-CN';

export const localLanguage = window.localStorage.getItem('lang');

const resources = Object.fromEntries(
  Object.entries({
    en,
    // 'zh-CN': zhCN,
  }).map(([lang, res]) => [
    lang,
    {
      translation: res,
    },
  ]),
);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    // fallbackLng: localLanguage ?? 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on('languageChanged', (lang) => {
  window.localStorage.setItem('lang', lang);
});

if (localLanguage) {
  cookie.set('googtrans', `/auto/${localLanguage}`);
}

// 大多数中英文映射
export const languageMap = {
  en: 'ename',
  'zh-CN': 'cname',
};

export default i18n;
