import type { RouteObject } from 'react-router-dom';
import type { ExpandRoute } from './type';

export function parseConfigToRoutes(
  routeConfigs: ExpandRoute[],
): RouteObject[] {
  return routeConfigs.map((config) => ({
    path: config.path,
    element: config.element,
    caseSensitive: true,
    children: config.children
      ? parseConfigToRoutes(config.children)
      : undefined,
  }));
}

/**
 * 通过页面入口路径解析获取页面路由配置
 * @param path 目标页面入口路径
 */
export function parseRouteInfoByPath(path: string) {
  const [, moduleName, pagePath] =
    path.match(/\/src\/features\/([^/]+)\/pages\/(.+)\.tsx/) ?? [];

  return {
    moduleName,
    pagePath,
  };
}
