import React from 'react';
import styles from './index.module.scss';
import TextItem from './TextItem';
import { Link } from 'react-router-dom';
import Icon from '@/components/Icon';
import Input from './Input';

export const Footer: React.FC = () => {
  // const location = useLocation();
  return (
    <footer className={styles.footer}>
      <TextItem title="ABOUT OUR STORE">
        <div className={styles.text}>
          Hexapus ACG is a one-stop shop that caters to and promotes anime
          figures, statues, and accessories related to the anime culture.
        </div>
        <div className={styles.text}>
          Here is the email address:{' '}
          <a href="mailto:info@hexapusacg.com" target="_blank" rel="noreferrer">
            info@hexapusacg.com
          </a>
          <div>@2023 2D Wave Limited. All rights reserved.</div>
        </div>
      </TextItem>
      <TextItem title="Info">
        <Link className={styles.link} to="/policy">
          Prvacy policy
        </Link>
        <Link className={styles.link} to="/terms-and-conditions">
          Terms & Conditions
        </Link>
      </TextItem>
      {/* <TextItem title="PRODUCT TYPE">
        <Link className={styles.link} to="/">
          Home
        </Link>
        <Link
          className={styles.link}
          to={`/mall/AllProducts${location.search}`}
        >
          Product
        </Link>
        <Link
          className={styles.link}
          to={`/mall/IchibanKujiOnline${location.search}`}
        >
          IchibanKuji Online
        </Link>
        <Link
          className={styles.link}
          to={`/mall/HexaKujiOnline${location.search}`}
        >
          HexaKuji Online
        </Link>
      </TextItem> */}
      <TextItem title="Let’s keep in touch">
        <div className={styles.icons}>
          <a
            className={styles.icon}
            href="https://www.facebook.com/profile.php?id=100086428467093"
            target="_blank"
            rel="noreferrer"
          >
            <Icon id="#hxicon-fb" />
          </a>
          {/* <a
            className={styles.icon}
            href="https://www.instagram.com/hexapus_acg/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon id="#hxicon-twitter" />
          </a> */}
          <a
            className={styles.icon}
            href="https://www.tiktok.com/@hexapus_acg_official"
            target="_blank"
            rel="noreferrer"
          >
            <Icon id="#hxicon-tiktok" />
          </a>
          <a
            className={styles.icon}
            href="https://www.instagram.com/hexapus_acg/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon id="#hxicon-instagram-fill" />
          </a>
        </div>
        <Input />
      </TextItem>
    </footer>
  );
};
