import { Fetcher } from 'openapi-typescript-fetch';
import { notification } from 'antd';
import type { paths } from './types';
import { useUserInfo } from '@/stores';

const fetcher = Fetcher.for<paths>();

fetcher.configure({
  baseUrl: import.meta.env.HEX_API_BASE_URL,
  use: [
    (url, init, next) => {
      init.headers.append('hexapus-user', useUserInfo.getState().getJWT());
      return next(url, init);
    },
  ],
});

export default fetcher;

export interface RequestOptions {
  reLogin?: boolean;
  noCheckCode?: boolean;
}

type InferBodyArgs<T> = T extends {
  data: infer DT;
}
  ? Omit<T, 'data'> & DT
  : T;

export const createRequest = <T extends keyof paths, MT extends keyof paths[T]>(
  path: T,
  method: MT,
) => {
  const request = fetcher.path(path).method(method).create();
  type ArgType = Parameters<typeof request>[0];
  return {
    [path]: async (
      arg: InferBodyArgs<ArgType>,
      init?: Parameters<typeof request>[1],
      options: RequestOptions = { reLogin: true, noCheckCode: false },
    ) => {
      let res: Awaited<ReturnType<typeof request>>;

      try {
        res = await request(arg as any, init);
      } catch (err: any) {
        res = err;
      }
      const { status, statusText } = res;
      const data = res.data as any;
      const dataCode = data?.code ?? 0;

      let errorMsg = '';
      let errorCode = 0;
      // 重新登录
      if (status === 403 || data?.code === -4) {
        // message.error('Please log in again!');
        if (options.reLogin) useUserInfo.getState().logout();
        return Promise.reject(data);
      }

      if (options.noCheckCode) return res.data;

      // 自己服务的报错
      if (status < 200 || status > 300 || dataCode !== 0) {
        errorCode = data?.code || status;
        errorMsg = data?.message || data?.msg || statusText || 'server error!';
      }

      if (errorCode === 10001) {
        // 10001 没有这个用户
        // 20006 邮箱已经注册过
        // 20001 密码错误
        notification.error({
          message: 'This email is not registered',
        });
        return Promise.reject(data);
      } else if (errorCode === 20006) {
        notification.error({
          message: 'This email has been registered!',
        });
        return Promise.reject(data);
      } else if (errorCode === 20001) {
        notification.error({
          message: 'Wrong password!',
        });
        return Promise.reject(data);
      } else if (errorCode === 20009 || errorCode === 10004) {
        notification.error({
          message: errorMsg,
        });
      }

      if (errorCode) {
        notification.error({
          message: `Error ${errorCode}: `,
          description: errorMsg || 'unknow error',
        });

        return Promise.reject(data);
      }

      return res.data;
    },
  }[path];
};
