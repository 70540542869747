import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getKVConfigs } from '@/apis';

export function useKVConfig(key: string, suspense = false) {
  const {
    data: configs,
    refetch,
    ...extraProps
  } = useQuery(
    [getKVConfigs.name],
    () => getKVConfigs({ page_size: 9999 }).then((res) => res.data?.list ?? []),
    {
      suspense,
    },
  );

  const config = useMemo(
    () => configs?.find((item) => item.key === key),
    [configs, key],
  );

  return {
    value: config?.value,
    refetch,
    ...extraProps,
  };
}
