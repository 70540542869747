import { CartItemType } from '@/stores';
import Picture from '@/components/Picture';
import styles from './index.module.scss';
import { PayChannelFormatorMap } from '@/configs/constants';
import Tag from '@/components/Tag';
import { useTranslation } from 'react-i18next';
import NumChange from '@/pages/Cart/NumChange';
import { Link } from 'react-router-dom';
import cls from 'classnames';

interface Props {
  info: CartItemType;
}

const tagMap = {
  1: {
    text: 'base.stock',
    color: '#0989E2',
  },
  2: {
    text: 'base.preOrder',
    color: '#01CBB3',
  },
};

const CartItem: React.FC<Props> = ({ info }) => {
  const { t } = useTranslation();
  const tag = tagMap[info.type!];
  const stock = info?.stock ?? 0;
  return (
    <div className={cls(styles.item)}>
      <Link
        className={styles.infoWrap}
        to={`/product/${info?.id}`}
        target="_blank"
      >
        <div className={cls(styles.img, stock === 0 ? 'nostock' : '')}>
          <Picture
            src={info.banner ? info.banner! : info.img ? info.img![0] : ''}
            width={72}
            height={72}
          />
        </div>
        <div className={styles.des}>
          <p className={styles.title}>{info.title}</p>
          {info.name && <p className={styles.sku}>{info.name}</p>}
          <span className={styles.price}>
            {PayChannelFormatorMap.get(info.pay_channel!)?.(info.price) ??
              'error'}
          </span>
          {tag && <Tag title={t(tag.text)} color={tag.color} />}
        </div>
      </Link>
      <NumChange id={info.id!} passNum={info.num ?? 0} max={stock} />
    </div>
  );
};

export default CartItem;
