import { createRequest } from '../fetcher';

/** 获取商品详情 */
export const getProductDetail = createRequest(
  '/customer/shop/product/{product_id}',
  'get',
);

/** 获取自组赏活动详情 */
export const getLotteryDetail = createRequest(
  '/customer/shop/lottery/{lottery_id}',
  'get',
);

/** 购买自组赏 */
export const payLottery = createRequest(
  '/customer/shop/lottery/{lottery_id}/pay',
  'post',
);

/** 获取一番赏活动详情 */
export const getIchibanDetail = createRequest(
  '/customer/shop/ichiban/{ichiban_id}',
  'get',
);

/** 获取一番赏盒子列表 */
export const getIchibanBoxList = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box',
  'get',
);

/** 获取一番赏盒子详情 */
export const getIchibanBoxDetail = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}',
  'get',
);

/** 购买一番赏 */
export const payIchiban = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}/buy',
  'post',
);

/** 获取一番赏排队列表 */
export const getIchibanQueue = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}/queue',
  'get',
);

/** 参加一番赏排队 */
export const joinIchibanQueue = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}/queue',
  'post',
);

/** 取消一番赏排队 */
export const quitIchibanQueue = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}/queue',
  'delete',
);

/** 获取一番赏我的排队状态 */
export const getMyIchibanQueueInfo = createRequest(
  '/customer/shop/ichiban/my_queue',
  'get',
);

/** 获取商品推荐列表 */
export const getRecommentProducts = createRequest(
  '/customer/shop/product_recommend',
  'get',
);

/** 获取一番赏推荐列表 */
export const getIchibanRecommentList = createRequest(
  '/customer/shop/ichiban_recommend',
  'get',
);

/** 获取自主赏推荐列表 */
export const getLottertRecommentList = createRequest(
  '/customer/shop/lottery_recommend',
  'get',
);

/** 免费抽一番赏 */
export const payFreeIchibanTickets = createRequest(
  '/customer/shop/ichiban/{ichiban_id}/box/{box_no}/free',
  'post',
);

/** 免费抽自主赏 */
export const getFreeLotteryTickets = createRequest(
  '/customer/shop/lottery/{lottery_id}/free',
  'post',
);
