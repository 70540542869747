import { createRequest } from '../fetcher';
/** 获取浏览记录列表 */
export const getHistory = createRequest('/customer/shop/user_history', 'get');

/** 获取收藏列表 */
export const getLikes = createRequest('/customer/shop/user_like', 'get');

/** 分解商品获得钻石 */
export const breakProduct = createRequest(
  '/customer/shop/break_down_user_goods',
  'post',
);

/** 钻石流水 */
export const getDiamondLog = createRequest(
  '/customer/assets/diamond_log',
  'get',
);

/** 金币流水 */
export const getCoinLog = createRequest('/customer/assets/gold_log', 'get');

/** 金币充值列表 */
export const getCoinPayList = createRequest(
  '/customer/pay/gold_price_list',
  'get',
);

/** 金币充值 */
export const rechargeCoin = createRequest('/customer/pay/charge_gold', 'get');
