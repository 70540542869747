import React from 'react';
import styles from './index.module.scss';

interface Props {
  title: string;
  onClick?: (e: React.MouseEvent) => void;
  color?: string;
}

export const PopupHeader: React.FC<Props> = ({ title, onClick, color }) => {
  return (
    <div className={styles.title} style={{ color: color || '#ffffff' }}>
      {title}
      <span className="close-icon" onClick={onClick}></span>
    </div>
  );
};

export default PopupHeader;
