import React from 'react';
import cls from 'classnames';
import { InputNumber as AntdInputNumber } from 'antd';
import type { InputNumberProps } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

export const InputNumber: React.FC<InputNumberProps> = ({
  className,
  ...extraProps
}) =>
  React.createElement(AntdInputNumber, {
    className: cls(styles.inputNumber, className),
    controls: {
      upIcon: <PlusOutlined />,
      downIcon: <MinusOutlined />,
    },
    ...extraProps,
  });
