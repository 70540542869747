import { Suspense, lazy } from 'react';
import { useRoutes, Outlet } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { parseConfigToRoutes } from './parse';
import type { ExpandRoute } from './type';
import { MainLayout } from '@/components/Layout/MainLayout';
import { PageLoading } from '@/components/Loading/Page';

// import HomePage from '@/pages/Home';
import Cart from '@/pages/Cart';
import NotFoundPage from '@/pages/NotFound';
import LogoPage from '@/pages/Login';
import Pass from '@/pages/Pass';
import Policy from '@/pages/Policy';
import TermsAndConditions from '@/pages/Policy/TermsAndConditions';

import { UserCenterRoutes } from '@/pages/UserCenter/routes';
import { OrderRoutes } from '@/pages/Order/routes';
import { ProductRoutes } from '@/pages/Product/routes';

const HomePage = lazy(() => import('@/pages/Home'));
const Mall = lazy(() => import('@/pages/Mall'));
const Submissions = lazy(() => import('@/pages/Submissions'));

const adminRouteConfig: ExpandRoute[] = [
  ...UserCenterRoutes,
  ...OrderRoutes,
  ...ProductRoutes,
];

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <MainLayout>
        <Suspense fallback={<PageLoading placeholder="loading..." />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/submissions',
        element: <Submissions />,
      },
      {
        path: '/policy',
        element: <Policy />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
      },
      {
        path: '/cart',
        element: <Cart />,
      },
      {
        path: '/mall/:mallid',
        element: <Mall />,
      },
      ...parseConfigToRoutes(adminRouteConfig),
    ],
  },
  {
    path: '/login',
    element: <LogoPage />,
  },
  {
    path: '/pass',
    element: <Pass />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export const AppRoutes = () => {
  const element = useRoutes(routes);
  return element;
};
