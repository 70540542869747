import React, { useState } from 'react';
import styles from './index.module.scss';
import { Popup } from 'antd-mobile';
import Icon from '@/components/Icon';
import PopupHeader from '@/components/PopupHeader';
import { Menu } from '../Menu';

export const MobileMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div className={styles.iconWrap}>
      <Icon
        id="#hxicon-menu"
        className={styles.icon}
        onClick={() => {
          setVisible(true);
        }}
      />
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        position="left"
        bodyStyle={{ width: '80vw', background: '#333333' }}
      >
        <div>
          <PopupHeader
            title="Menu"
            onClick={() => {
              setVisible(false);
            }}
          />
          <Menu onClick={() => setVisible(false)} />
        </div>
      </Popup>
    </div>
  );
};

export default MobileMenu;
