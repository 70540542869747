import React from 'react';
import type { ExpandRoute } from '@/routes/type';
import { ActivityLayout } from '@/components/Layout/ActivityLayout';

const ProductDetailPage = React.lazy(() => import('./ProductDetail'));
const LotteryDetailPage = React.lazy(() => import('./LotteryDetail'));
const IchibanDetailPage = React.lazy(() => import('./IchibanDetail'));

export const ProductRoutes: ExpandRoute[] = [
  {
    path: 'product',
    children: [
      {
        path: ':id',
        element: <ProductDetailPage />,
        metas: {
          title: 'Product detail',
        },
      },
      {
        path: 'ichiban/:id',
        element: <ActivityLayout />,
        children: [
          {
            path: '',
            index: true,
            element: <IchibanDetailPage />,
            metas: {
              titlt: 'Ichiba detail',
            },
          },
        ],
      },
      {
        path: 'hexakuji/:id',
        element: <ActivityLayout />,
        children: [
          {
            path: '',
            index: true,
            element: <LotteryDetailPage />,
            metas: {
              titlt: 'Lottery detail',
            },
          },
        ],
      },
    ],
  },
];
