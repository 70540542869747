import create from 'zustand';
import { getLoginUserInfo } from '@/apis';

type UserInfo = NonNullable<
  Awaited<ReturnType<typeof getLoginUserInfo>>['data']
>;

export const JWT_KEY = 'hexapus-mal-jwt';

interface UserStore {
  userInfo?: UserInfo;
  getLoginUserInfo: () => Promise<UserInfo | undefined | null>;
  getJWT: () => string;
  login: (key: string) => void;
  logout: () => void;
}

export const useUserInfo = create<UserStore>((set) => ({
  getJWT() {
    return localStorage.getItem(JWT_KEY) ?? 'unknow';
  },

  async getLoginUserInfo() {
    try {
      const res = await getLoginUserInfo({}, undefined, { reLogin: false });
      set({
        userInfo: res.data,
      });
      return res.data;
    } catch {
      return null;
    }
  },

  async login(key: string) {
    window.localStorage.setItem(JWT_KEY, key);
  },

  logout() {
    window.localStorage.removeItem(JWT_KEY);
    window.location.href = `${import.meta.env.HEX_APP_BASE_URL.replace(
      /\/$/,
      '',
    )}/login?redirect_url=${encodeURIComponent(
      [window.location.pathname, window.location.search]
        .filter(Boolean)
        .join('?'),
    )}`;
  },
}));
