// 需要展示在首页的商品分类 store
import create from 'zustand';
import { getProductKindsList } from '@/apis';
import type { InferArrayValue } from '@/types/infer';

export type ProductKindItem = InferArrayValue<
  NonNullable<Awaited<ReturnType<typeof getProductKindsList>>['data']>
>;

interface ProductKinds {
  navProductKindList: ProductKindItem[];
  homeProductKindList: ProductKindItem[];
  getHomeProductKindList: () => Promise<ProductKindItem[] | undefined | null>;
}

// 所有商品栏目
const defaultAll = {
  class_type: 1,
  cname: '所有商品',
  ename: 'All Products',
  id: 0,
  online: true,
  position: 2,
  weight: 9999,
};

export const useProductKinds = create<ProductKinds>((set) => ({
  homeProductKindList: [],
  navProductKindList: [],
  async getHomeProductKindList() {
    try {
      const res = await getProductKindsList({});
      const list = res?.data as unknown as ProductKindItem[];
      if (list && list.length) {
        set({
          homeProductKindList: list
            .filter((item) => item.position === 1)
            .sort((b, a) => (a.weight || 0) - (b.weight || 0)),
          navProductKindList: [defaultAll, ...list].sort(
            (b, a) => (a.weight || 0) - (b.weight || 0),
          ),
        });
      }
      return list;
    } catch (error) {
      return null;
    }
  },
}));
