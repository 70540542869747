import type { ExpandRoute } from '@/routes/type';

import UserCenterLayout from '../Layout';
import { UserCenterChildrenRoutes } from './children';

export const UserCenterRoutes: ExpandRoute[] = [
  {
    path: 'my',
    element: <UserCenterLayout />,
    children: UserCenterChildrenRoutes,
  },
];
