import create from 'zustand';

export type Mode = 'normal' | 'activity';

export interface ThemeConfig {
  mode: Mode;
  setMode: (mode: Mode) => void;
}

export const useThemeConfig = create<ThemeConfig>((set) => ({
  mode: 'normal',

  setMode(mode: Mode) {
    set({
      mode,
    });
  },
}));
