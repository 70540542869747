/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { Input, Select, Dropdown, Menu, Spin, Empty } from 'antd';
import styles from './index.module.scss';
import { useProductKinds } from '@/stores';
import useUrlState from '@ahooksjs/use-url-state';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMallParams } from '@/stores';
import { omit } from 'lodash';
import { getSearchProduct } from '@/apis';
import { debounce } from 'lodash';
import type { InferArrayValue } from '@/types/infer';
import type { InputRef } from 'antd';

export type SearchItemOption = InferArrayValue<
  NonNullable<NonNullable<Awaited<ReturnType<typeof getSearchProduct>>['data']>>
>;

const processedImg = (url: string) => {
  return `${url}?x-oss-process=image/resize,m_fill,h_100,w_100`;
};

interface QueryType {
  title?: string;
  price?: string;
  ips?: string;
  factory?: string;
}

const highlightText = (text: string, highlight = '') => {
  if (!highlight.trim()) {
    return text;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ fontWeight: 'bold', color: '#007697' }}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

const { Option } = Select;
const HeaderSearch: React.FC = () => {
  const { navProductKindList } = useProductKinds();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const { updateMallParams } = useMallParams();
  const [query] = useUrlState<QueryType>({});
  // 左边商品分类选择
  const [selectvalue, setSelectValue] = useState<number>();
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const inputRef = useRef<InputRef>(null);
  const [searchTerm, setSearchTerm] = useState<string>(query.title);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SearchItemOption[]>([]);

  const handleSearch = (val: string) => {
    const item = navProductKindList.find((item) => item.id === selectvalue);
    if (item) {
      const mallid = (item.ename || '').replace(/\s*/g, '');
      updateMallParams({
        title: val || '',
      });
      if (
        location.pathname.indexOf('/mall') === -1 ||
        mallid !== urlParams.mallid
      ) {
        navigate(`/mall/${mallid}`);
      }
    }
  };

  const getProduct = async (title = '') => {
    try {
      setIsLoading(true);
      if (!title) {
        setDropdownVisible(false);
      }
      const res = await getSearchProduct({
        title,
        class_id: selectvalue!,
      });
      setIsLoading(false);
      setOptions(res.data ?? []);
      if (!dropdownVisible && title) {
        setDropdownVisible(true);
        if (inputRef.current?.input) {
          inputRef.current.input.focus(); // 手动聚焦
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce((query: string) => {
      getProduct(query);
    }, 500),
    [],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!isComposing) {
      debouncedFetch(value);
    }
  };
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = (
    e: React.CompositionEvent<HTMLInputElement>,
  ) => {
    setIsComposing(false);
    const value = e.currentTarget.value;
    setSearchTerm(value);
    debouncedFetch(value);
  };

  const handleSelect = (option: SearchItemOption) => {
    const title = option.title!;
    if (inputRef.current?.input) {
      inputRef.current.input.value = title!; // 手动设置 Input 的值
      inputRef.current.input.focus(); // 手动聚焦
    }
    setSearchTerm(title!);
    setDropdownVisible(false);

    // handleSearch(title!);
    navigate(
      `/product/${option.id}${selectvalue ? `?kindId=${selectvalue}` : ''}`,
    );
  };
  const menu = (
    <Menu
      onClick={({ key }) =>
        handleSelect(
          options.find((option: SearchItemOption) => `${option.id}` === key)!,
        )
      }
    >
      {options.length === 0 && <Empty description="" />}
      {options.map((option) => (
        <Menu.Item key={`${option.id}`}>
          <div className={styles.menuItem}>
            <img
              src={processedImg(option.img ? option.img![0] : '')}
              alt={option.title}
              className={styles.searchImg}
            />
            <span className={styles.swarchTitle}>
              {highlightText(option.title || '', searchTerm)}
            </span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (navProductKindList && navProductKindList.length) {
      setSelectValue(navProductKindList[0]?.id);
    }
  }, [navProductKindList]);
  useEffect(() => {
    // 仅同步一次url的筛选参数
    updateMallParams(
      omit(
        {
          ...query,
          ip_id__in: query.ips,
          price__range: query.price,
          factory__in: query.factory,
        },
        ['ips', 'price', 'factory'],
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Input.Group compact className={styles.search}>
      <Select
        className="header-search-select"
        suffixIcon={<span className="icon-caret"></span>}
        getPopupContainer={(triggerNode) =>
          triggerNode.closest(`.${styles.search}`)!
        }
        bordered={false}
        value={selectvalue}
        onChange={setSelectValue}
      >
        {navProductKindList.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.ename}
          </Option>
        ))}
      </Select>
      <Dropdown
        overlay={menu}
        visible={dropdownVisible}
        overlayClassName={styles.menu}
        trigger={['click']}
        getPopupContainer={(triggerNode) =>
          triggerNode.closest(`.${styles.search}`)!
        }
        onVisibleChange={(flag) => {
          if (!isComposing) {
            setDropdownVisible(flag);
          } else {
            if (flag || inputRef.current?.input === document.activeElement) {
              setDropdownVisible(flag);
            } else {
              setDropdownVisible(false);
            }
          }
        }}
      >
        <Input
          allowClear
          ref={inputRef}
          defaultValue={query.title || ''}
          onPressEnter={(e: any) => handleSearch(e?.target?.value ?? '')}
          placeholder="Search products..."
          enterKeyHint="search"
          bordered={false}
          // value={searchTerm}
          onChange={handleChange}
          // onFocus={onInputFocus}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          suffix={isLoading ? <Spin /> : null}
          autoFocus={true}
        />
      </Dropdown>
    </Input.Group>
  );
};

export default memo(HeaderSearch);
