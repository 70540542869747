import React from 'react';
import { Result } from 'antd';
import { Helmet } from 'react-helmet-async';
import style from './index.module.scss';
import useUrlState from '@ahooksjs/use-url-state';

const NotFoundPage: React.FC = () => {
  const [query] = useUrlState<{
    error_msg?: string;
  }>({});
  return (
    <>
      <Helmet>
        <title>页面不存在</title>
      </Helmet>
      <div className={style.notFoundPage}>
        <Result
          status="500"
          title={query.error_msg || '系统维护中，暂时不可用'}
        />
      </div>
    </>
  );
};

export default NotFoundPage;
