import React from 'react';
import style from './index.module.scss';
import { useKVConfig } from '@/hooks';

const TermsAndConditionsPage: React.FC = () => {
  const { value } = useKVConfig(`CUSTOM_PAGE_terms-and-conditions`, true);

  return (
    <div
      className={style.container}
      dangerouslySetInnerHTML={{ __html: value! }}
    ></div>
  );
};

export default TermsAndConditionsPage;
