import { createRequest } from '../fetcher';
/** 添加浏览记录 */
export const addHistory = createRequest(
  '/customer/shop/user_history/{type}/{item_id}',
  'post',
);

export const addLike = createRequest(
  '/customer/shop/user_like/{type}/{item_id}',
  'post',
);

export const removeLike = createRequest(
  '/customer/shop/user_like/{type}/{item_id}',
  'delete',
);

export const getLikeInfo = createRequest(
  '/customer/shop/user_like/{type}/{item_id}',
  'get',
);
