import { createRequest } from '../fetcher';

/** 获取普通商品商城列表 */
export const getMallList = createRequest('/customer/shop/product', 'get');

/** 获取普通商品商城列表 */
export const getLotteryList = createRequest('/customer/shop/lottery', 'get');

/** 获取普通商品商城列表 */
export const getIchibanList = createRequest('/customer/shop/ichiban', 'get');

/** 获取购物车列表 */
export const getCartList = createRequest(
  '/customer/shop/shopping_cart_info',
  'get',
);

/** 修改购物车 */
export const updateCartList = createRequest(
  '/customer/shop/shopping_cart_info',
  'post',
);

/** 删除购物车 */
export const deleteCartList = createRequest(
  '/customer/shop/shopping_cart_info/{goods_id}',
  'delete',
);

/** 新建临时购物车订单 */
export const createCartOrder = createRequest(
  '/customer/shop/multi_temp_order',
  'post',
);

/** 查看商品返现和钻石抵扣 */
export const getCashback = createRequest(
  '/customer/shop/pay_diamond_conf',
  'get',
);

// 获取搜索推荐
export const getSearchProduct = createRequest(
  '/customer/shop/search_product',
  'get',
);
