import cls from 'classnames';
type Props = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
};

const Icon: React.FC<Props> = ({ id, className, onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      className={cls('hx-icon', className)}
      style={style}
      aria-hidden="true"
    >
      <use xlinkHref={id}></use>
    </svg>
  );
};

export default Icon;
