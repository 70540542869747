import React from 'react';
import type { ExpandRoute } from '@/routes/type';

const OrderDetailPage = React.lazy(() => import('./Detail'));
const TempOrderDetailPage = React.lazy(() => import('./TempDetail'));
const CartTempOrderDetailPage = React.lazy(() => import('./CartTempDetail'));
const OrderResultPage = React.lazy(() => import('./Result'));
const PreOrderResultPage = React.lazy(() => import('./PreResult'));
const DeliveryTempOrderDetailPage = React.lazy(
  () => import('./DeliveryTempDetail'),
);
const DeliveryOrderDetailPage = React.lazy(() => import('./DeliveryDetail'));
const DeliveryOrderResultPage = React.lazy(() => import('./DeliveryResult'));

export const OrderRoutes: ExpandRoute[] = [
  {
    path: 'order',
    children: [
      // 订单详情页
      {
        path: ':id',
        element: <OrderDetailPage />,
        metas: {
          title: 'Order detail',
        },
      },
      // 临时购买页面（第一次点击购买按钮生成页面）
      {
        path: 'temp/:id',
        element: <TempOrderDetailPage />,
        metas: {
          title: 'Order detail',
        },
      },
      // 购物车订单页面
      {
        path: 'cart/:id',
        element: <CartTempOrderDetailPage />,
        metas: {
          title: 'Order Detail',
        },
      },
      // 订单结果页
      {
        path: 'result',
        element: <OrderResultPage />,
        metas: {
          title: 'Order Result',
        },
      },
      {
        path: 'pre-result',
        element: <PreOrderResultPage />,
        metas: {
          title: 'Pre Order Result',
        },
      },
      // 物流订单页
      {
        path: 'delivery',
        metas: {
          title: 'Delivery Order Detail',
        },
        children: [
          {
            path: 'result',
            element: <DeliveryOrderResultPage />,
          },
          {
            path: 'temp/:id',
            element: <DeliveryTempOrderDetailPage />,
          },
          {
            path: ':id',
            element: <DeliveryOrderDetailPage />,
          },
        ],
      },
    ],
  },
];
