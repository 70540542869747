import { createRequest } from '../fetcher';

/** 获取盒柜列表 */
export const getWarehouseData = createRequest(
  '/customer/shop/user_goods',
  'get',
);

/** 获取物流详情 */
export const getLogisticsDetail = createRequest(
  '/customer/shop/logistics/{logistics_id}',
  'get',
);

/** 获取用户钱包 */
export const getUserMoney = createRequest('/customer/assets/money', 'get');

/** 添加用户浏览历史 */
export const addUserHostory = createRequest(
  '/customer/shop/user_history/{type}/{item_id}',
  'post',
);
