import React, { useState } from 'react';
import styles from './index.module.scss';
import { Input } from 'antd';
import { subscribeEmail } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { testEmail } from '@/utils';

const Icon: React.FC = () => {
  const createMutate = useMutation(subscribeEmail);
  const [value, setValue] = useState('');
  const [show, setShow] = useState(false);
  const handleSubmit = async () => {
    if (!testEmail(value)) {
      return;
    }
    await createMutate.mutateAsync({
      email: value,
    });
    setShow(true);
    setValue('');
    setTimeout(() => {
      setShow(false);
    }, 2500);
  };
  return (
    <div className={styles.footerInput}>
      <Input
        className={styles.input}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Your email"
        onPressEnter={handleSubmit}
      />
      {show && <span className={styles.text}>Subscribe Successfully!</span>}
      <span className={styles.submit} onClick={handleSubmit}>
        Subscribe
      </span>
    </div>
  );
};

export default Icon;
