// 需要展示在首页的商品分类 store
import create from 'zustand';
import {
  getCartList,
  updateCartList,
  deleteCartList,
  createCartOrder,
} from '@/apis';
import type { InferArrayValue } from '@/types/infer';
import { roundFractional } from '@/utils';

export type CartItemType = InferArrayValue<
  NonNullable<
    NonNullable<Awaited<ReturnType<typeof getCartList>>['data']>['goods_info']
  >
>;

type UpdateParams = Parameters<typeof updateCartList>[0];

type CartOrder = {
  goods_list: {
    goods_id: number;
    num: number;
  }[];
};

interface Cart {
  // 是否弹出购物车弹窗
  open: boolean;
  setOpen: (visible: boolean) => void;
  cartList: CartItemType[];
  cartTotal: number;
  cartPrice: number;
  getCartList: () => Promise<any>;
  // 新增或修改购物车, openCart为加入购物车
  updateCartList: (params: UpdateParams, openCart?: boolean) => Promise<any>;
  deleteCartList: (goods_id: number) => Promise<any>;
  // 创建临时订单
  createCartOrder: (params: CartOrder) => Promise<any>;
  clearCart: () => void;
}

export const useCarts = create<Cart>((set, get) => ({
  open: false,
  setOpen(visible: boolean) {
    set((state) => ({
      ...state,
      open: visible,
    }));
  },
  cartList: [],
  cartTotal: 0,
  cartPrice: 0,
  async getCartList() {
    try {
      const res = await getCartList({ page: 1, page_size: 99 });
      const list = res.data?.goods_info;
      if (list && list.length) {
        const { price, total } = list.reduce(
          (sum, item) => {
            const num = Math.min(item.num ?? 0, item.stock ?? 0);
            const price = item.price || 0;
            return {
              price: sum.price! + num * price,
              total: sum.total! + num,
            };
          },
          {
            price: 0,
            total: 0,
          },
        );
        set({
          cartList: list ?? [],
          cartTotal: total,
          cartPrice: roundFractional(price || 0, 2),
        });
      }
      return res.data;
    } catch (error) {
      return null;
    }
  },
  async updateCartList(params: UpdateParams, openCart = false) {
    // openCart 只用于addToCart
    try {
      const state = get();
      let list = state.cartList;
      let cartTotal = state.cartTotal;
      let cartPrice = state.cartPrice;
      const getCartList = state.getCartList;
      const idx = list.findIndex((item) => params.goods_id === item.id);
      // 最终更新的数量
      let updateNum = 1;
      if (idx > -1) {
        // 修改
        const item = list[idx];
        const num = item.num ?? 0;
        const price = item.price ?? 0;
        const paramsNum = params.num ?? 0;
        updateNum = openCart ? num + paramsNum : paramsNum;
        const dis = openCart ? paramsNum : paramsNum - num;
        cartTotal = cartTotal + dis;
        // 解决精度问题
        cartPrice = cartPrice + price * dis;
        list = list.map((item, index) => {
          if (idx === index) {
            return {
              ...item,
              num: updateNum,
            };
          }
          return item;
        });
      } else {
        updateNum = params.num ?? 1;
      }
      // 先手工计算再掉接口
      set({
        cartList: list,
        cartTotal,
        cartPrice: roundFractional(cartPrice || 0, 0),
      });
      // 再次打开应该是加1
      params.num = updateNum;
      await updateCartList(params);
      if (!!openCart) {
        if (idx === -1) {
          // 新加入类目需要更新一下购物车
          await getCartList();
        }
        set({
          open: true,
        });
      }
      return true;
    } catch (error) {
      return null;
    }
  },
  async deleteCartList(id: number) {
    try {
      set((state) => {
        const idx = state.cartList.findIndex((item) => id === item.id);
        const list = state.cartList;
        let cartTotal = state.cartTotal;
        let cartPrice = state.cartPrice;
        if (idx !== -1) {
          const item = list[idx];
          const num = Math.min(item.num ?? 0, item.stock ?? 0);
          const price = item.price || 0;
          cartTotal -= num;
          cartPrice -= num * price;
          list.splice(idx, 1);
        }
        return {
          cartList: list,
          cartTotal,
          cartPrice: roundFractional(cartPrice || 0, 0),
        };
      });
      await deleteCartList({
        goods_id: id,
      });
      return true;
    } catch (error) {
      return null;
    }
  },
  // 创建临时订单
  async createCartOrder(params: CartOrder) {
    try {
      const res = await createCartOrder(params);
      return res.data?.id;
    } catch (error) {
      return null;
    }
  },
  clearCart() {
    set({
      cartList: [],
      cartTotal: 0,
      cartPrice: 0,
    });
  },
}));
