import React from 'react';
import { Button } from 'antd';
import Icon from '@/components/Icon';
import { useQuery } from '@tanstack/react-query';
import { Navigate, Link } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import Email from './Email';
import { getGoogleLoginData, getFacebookLoginData } from '@/apis';
import { useUserInfo } from '@/stores';
import LoginMain from '@/components/LoginMain';

import styles from './index.module.scss';

const LoginPage: React.FC = () => {
  const [query] = useUrlState<{
    jwt?: string;
    redirect_url?: string;
  }>({});
  const login = useUserInfo((state) => state.login);
  const googleLoginData = useQuery([getGoogleLoginData.name], () =>
    getGoogleLoginData({
      redirect_url: window.location.href,
    }).then((res) => res.data),
  );
  const facebookLoginData = useQuery([getFacebookLoginData.name], () =>
    getFacebookLoginData({
      redirect_url: window.location.href,
    }).then((res) => res.data),
  );
  if (query.jwt) {
    login(query.jwt);
    return (
      <Navigate
        to={decodeURIComponent(query.redirect_url ?? '') || '/'}
        replace={true}
      />
    );
  }

  return (
    <LoginMain title="Sign in to Hexapus">
      <Email />
      <div className={styles.loginActionArea}>
        <div className={styles.thirdPartyLoginGroup}>
          <Button
            className={styles.loginBtn}
            loading={googleLoginData.isFetching}
            onClick={() => (window.location.href = googleLoginData.data!)}
          >
            <Icon className={styles.loginBtnIcon} id="#hxicon-google" />
            Login with Google
          </Button>
          <Button
            className={styles.loginBtn}
            loading={facebookLoginData.isFetching}
            type="primary"
            onClick={() => (window.location.href = facebookLoginData.data!)}
          >
            <Icon className={styles.loginBtnIcon} id="#hxicon-facebook" />
            Login with Facebook
          </Button>
          <p className={styles.tip}>
            Temporarily unable to log in using Facebook.
          </p>
          <p className={styles.registerText}>
            Not registered?{' '}
            <Link
              className={styles.register}
              to={`/pass?type=register&redirect_url=${encodeURIComponent(
                query.redirect_url,
              )}`}
            >
              Create an account?
            </Link>
          </p>
          {/* <Button className={styles.loginBtn} disabled={true}>
                <Icon className={styles.loginBtnIcon} id="#hxicon-wechat" />
                Login with Wechat
              </Button> */}
        </div>
      </div>
    </LoginMain>
  );
};

export default LoginPage;
