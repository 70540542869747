import React from 'react';
import type { ExpandRoute } from '@/routes/type';
import { groupBy } from 'lodash';

const HomePage = React.lazy(() => import('../Home'));
const OrdersPage = React.lazy(() => import('../Order'));
const WarehousePage = React.lazy(() => import('../Warehouse'));
const ProfilePage = React.lazy(() => import('../Profile'));
const AddressPage = React.lazy(() => import('../Address'));
const Like = React.lazy(() => import('../Like'));
const Historys = React.lazy(() => import('../Historys'));
const Diamonds = React.lazy(() => import('../Diamonds'));
const Coins = React.lazy(() => import('../Coins'));

export const UserCenterChildrenRoutes: ExpandRoute[] = [
  {
    path: '',
    name: 'center.home',
    index: true,
    element: <HomePage />,
  },
  {
    path: 'order',
    name: 'center.order',
    element: <OrdersPage />,
    metas: {
      group: 'center.deal',
    },
  },
  {
    path: 'warehouse',
    name: 'center.shelf',
    element: <WarehousePage />,
    metas: {
      group: 'center.deal',
    },
  },
  {
    path: 'like',
    name: 'center.like',
    element: <Like />,
    metas: {
      group: 'center.focus',
    },
  },
  {
    path: 'history',
    name: 'center.record',
    element: <Historys />,
    metas: {
      group: 'center.focus',
    },
  },
  {
    path: 'coin',
    name: 'center.coin',
    element: <Coins />,
    metas: {
      group: 'center.wallet',
    },
  },
  {
    path: 'diamond',
    name: 'center.diamond',
    element: <Diamonds />,
    metas: {
      group: 'center.wallet',
    },
  },
  {
    path: 'profile',
    name: 'center.info',
    element: <ProfilePage />,
    metas: {
      group: 'center.self',
    },
  },
  {
    path: 'address',
    name: 'center.address',
    element: <AddressPage />,
    metas: {
      group: 'center.self',
    },
  },
];

export const UserCenterMenus = Array.from(
  Object.entries(
    groupBy(
      UserCenterChildrenRoutes.filter((item) => item.metas?.group).map(
        (item) => ({
          name: item.name,
          path: item.path!,
          group: item.metas!.group,
        }),
      ),
      (item) => item.group,
    ),
  ),
);

export const UserCenterMenusMap = UserCenterChildrenRoutes.reduce(
  (target, route) => {
    if (route.path) {
      target[route.path] = route.name;
    } else {
      target['my'] = route.name;
    }
    return target;
  },
  {},
);
