export default {
  test: {
    say: 'Welcome to React and react-i18next',
  },
  base: {
    stock: 'In stock',
    preOrder: 'Pre-sale',
    defaultAddress: 'Default',
    noAddress: 'No Address',
    createAddress: 'New Address',
    changeAddress: 'Select Address',
    logistics: {
      noFreeShipping: 'No free shipping',
      freeShippingOverFive: 'Free shipping over five',
    },
  },
  home: {
    hotip: 'Find by source',
  },
  mall: {
    status: 'Sale status',
    price: 'Price',
    ip: 'Series Name',
    new: 'Latest arrival',
    hightPrice: 'Price: high to low',
    lowPrice: 'Price: low to high',
    hot: 'Best Seller',
    like: 'Most Likes',
  },
  center: {
    all: 'All Products',
    home: 'Personal Center',
    deal: 'Deal',
    order: 'Orders',
    shelf: 'Shelf',
    focus: 'Focus',
    like: 'Favorites',
    record: 'View History',
    wallet: 'Wallet',
    coin: 'My Coin',
    coinWater: 'Coin Bill',
    diamond: 'My Diamond',
    diamondWater: 'Diamond Bill',
    self: 'Personal',
    info: 'Information',
    address: 'Shipping Addresses',
    time: 'Time',
    billText: 'Income And Expenses',
    detail: 'Detail',
    check: 'View details',
    coinAccount: 'Gold account',
    accountRest: 'Account Balance',
    charge: 'Recharge Gold',
    payMount: 'Purchase Quantity',
    checkoutAddress: 'Payment Address',
    checkoutWay: 'Payment Method',
    chargeTip: 'Custom recharge amount（1-9999）',
    rechrage: 'Rechrage',
    diamondAccount: 'Diamond Account',
    getMore: 'Get more diamonds',
    break: 'Break',
    warehouse: {
      buyTime: 'Purchased on {{time}}',
      viewLogistics: 'View Logistics',
      applyShipment: 'Apply Shipment',
      status: {
        temporary: 'Temporary',
        notShipped: 'UnShipped',
        shipped: 'Shipped',
        unFinalPay: 'FinalPayment',
      },
      allClass: 'All types',
      selectedHint: 'items selected',
      result: {
        title: 'Success Apply Shipment',
        subTitle:
          'The product has entered the pending delivery state. After delivery, you can check the logistics in the delivered list in the box cabinet.',
        goWarehouse: 'Back Box Shelf',
      },
    },
  },
  components: {
    uploadAvatar: {
      edit: 'Modify Avatar',
    },
    table: {
      operate: 'Operate',
    },
  },
  order: {
    status: {
      unPay: 'Unpaid',
      payDeposit: 'Unpaid balance',
      paidOff: 'Paid',
      cancel: 'Cancel',
      success: 'Success',
    },
    detail: {
      deliveryMode: 'Shipping Method',
      payMode: 'Payment Method',
    },
    deliveryDetail: {
      productInfo: 'Product({{type}})',
      quantity: 'Quantity',
      postage: 'Postage',
      title: 'Please confirm the list of products to be shipped',
    },
    result: {
      title: 'Thank you!',
      subTitle:
        'If you failed to make the payment, you can always use "pay now" in this order to make the payment again.',
      goWarehouse: 'View Box Shelf',
      goProduct: 'Return to product',
    },
    preResult: {
      title: 'Deposit payment successful',
      subTitle:
        'Please pay attention to the payment time of the balance, so as not to close the transaction due to the failure to pay the balance.',
      goOrderDetail: 'Check Order',
      goProduct: 'Return to product',
    },
    list: {
      startFinalPayTime: 'Start paying at {{time}}',
      stopFinalPayTime: 'Final payment due after {{time}}',
      searchPlaceholder: 'Please input order no',
      tabs: {
        all: 'All',
        bought: 'Purchased',
        unpay: 'UnPay',
        payDeposit: 'UnpaidBalance',
        cancel: 'Cancel',
      },
      table: {
        productInfo: 'Product Info',
        unitPrice: 'Unit Price',
        quantity: 'Quantity',
        total: 'Total Price',
        status: 'Status',
        operate: 'Operate',
        payNow: 'Pay Now',
        detail: 'Detail',
      },
    },
  },
  modal: {
    goldChecker: {
      title: 'Not enough gold coin :(',
      content: 'Please recharge before continuing to buy.',
      okText: 'Recharge',
    },
    ichibanQueue: {
      title: 'Are you sure you want to leave another team?',
      content:
        'The current user is queuing for other items and needs to exit to purchase the current item.',
      okText: 'Leave',
    },
  },
  detail: {
    logisticsInfo: 'Estimated {{price}} Shipping{{otherInfo}}',
    logisticsOtherInfo: ', Free shipping for five pieces of goods',
    stock: 'Remaining stock: ',
    buy: 'Buy Now',
    like: 'Add To Favorites',
    unlike: 'Remove From Like',
    bout: 'Ticket',
  },
  product: {
    logisticsType: {
      expressShipping: 'Standard Shipping',
      putInBox: 'Put In Box',
    },
    detail: {
      deposit: 'Deposit',
      unitPrice: 'Unit Price',
      quantity: 'Quantity',
      total: 'Total',
      productInfo: 'Product Info',
      level: 'level',
      sellOut: 'Sold Out',
      howToPlay: 'How To Play',
    },
    payInfo: {
      totalPrice: 'Total Price (before tax):',
      postage: 'Postage:',
      totalPayablePrice: 'Total Payable Price:',
      depositPrice: 'Deposit Price (before tax):',
      depositActualPrice: 'Deposit Actual Price:',
      finalPrice: 'Final Price (before tax):',
      finalPayablePrice: 'Final Payable Price:',
      totalActualPrice: 'Total Actual Price:',
      payAll: 'Pay',
      payBalance: 'Pay the balance',
      paying: 'Now is Paying',
    },
    activity: {
      common: {
        probability: 'Commodity Probability',
        showcase: 'Commodity Showcase',
        buyModal: {
          title: 'Buy Goods',
          num: 'Quantity',
          okText: 'Buy',
        },
        resultModal: {
          title: 'Congratulations',
          reBuyBtnText: 'Continue',
          hint: "Goods have been automatically deposited into your account's 'Shelf'",
        },
      },
      name: {
        lottery: 'Hexa Kuji',
        ichiban: 'Ichiban Kuji',
      },
      lotteryBuyModal: {
        title: 'Buy Hexa Kuji',
      },
      ichibanBuyModal: {
        title: 'Buy Ichiban Kuji',
      },
      lotteryPlayGuide: '这是玩法介绍',
      ichibanPlayGuide: '这是玩法介绍',
      ichibanQueueStatus: '  people are waiting',
      ichibanInQueueStatusStart: 'There are ',
      ichibanInQueueStatusMiddle: ' people waiting in front, ',
      ichibanInQueueStatusEnd: 'quit the queue',
      ichibanQuitHintTitlt: 'Are you sure you queue up?',
      ichibanQuitHintOkText: 'Yes',
      ichibanChooseBox: 'Choose Box',
      ichibanBox: 'Box',
      ichibanRemainder: 'Remainder',
      ichibanWaitingHint: 'In Queue, Please Wait',
      ichibanRemainingTime: 'Remaining Time: ',
    },
  },
};
