import React from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, message } from 'antd';
import { reset } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import { omit } from 'lodash';

const Reset: React.FC = () => {
  const [query] = useUrlState<{
    token?: string;
  }>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const mutate = useMutation(reset);
  const token = query.token;
  const onFinish = async (values: any) => {
    if (!token) {
      message.error(
        'Please check your eamil and click the link to reset password!',
      );
      return;
    }
    await mutate.mutateAsync({
      ...omit(values, 'confirm'),
      token,
    });
    message.success('Reset password Successfully!');
    navigate(`/login`);
  };
  return (
    <div className={styles.reset}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Password"
          name="password"
          tooltip="Special characters are @$!%*?&,./~;"
          rules={[
            {
              required: true,
              validator(_, value: string) {
                if (!value?.length) {
                  return Promise.reject('Please input your password!');
                }
                if (value.length! < 8) {
                  return Promise.reject('At least 8 characters');
                }
                if (value.search(/\s/) != -1) {
                  return Promise.reject('Cannot contain whitespace characters');
                }
                const re =
                  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&,./~;])[a-zA-Z\d@$!%*?&,./~;]+$/;
                if (!re.test(value)) {
                  return Promise.reject(
                    'Contain letters, numbers, special characters',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.btn}
            size="large"
            type="primary"
            htmlType="submit"
            loading={mutate.isLoading}
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Reset;
