import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, Statistic, message } from 'antd';
import { forgot } from '@/apis';
import { Link } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';

const { Countdown } = Statistic;

const Forgot: React.FC = () => {
  const [query] = useUrlState<{
    redirect_url?: string;
    email?: string;
  }>({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [deadline, setDeadline] = useState<number>(0);
  const onFinish = async (values: any) => {
    if (deadline > 0) {
      message.error('Send another email after 60 seconds!');
      return;
    }
    setLoading(true);
    try {
      await forgot(values);
      setLoading(false);
      setDeadline(Date.now() + 1000 * 60);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!!query.email) {
      form.setFieldsValue({
        email: decodeURIComponent(query.email),
      });
    }
  }, [form, query.email]);
  return (
    <div className={styles.forgot}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          extra="We must make sure that your are a human."
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email!',
            },
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item>
          {deadline > 0 && (
            <p className={styles.count}>
              <span>An email to recover the password has been sent. </span>
              <span className={styles.down}>
                Send another email after
                <Countdown
                  format="ss"
                  value={deadline}
                  onFinish={() => setDeadline(0)}
                />
                s
              </span>
            </p>
          )}
          <Button
            className={styles.btn}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Recover
          </Button>
        </Form.Item>
      </Form>
      <p className={styles.text}>
        Remembered your password? Back to
        <Link
          className={styles.sign}
          to={`/login?type=register&redirect_url=${encodeURIComponent(
            query.redirect_url,
          )}`}
        >
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default Forgot;
