import React, { useState, useMemo } from 'react';
import styles from './index.module.scss';
import { Popup } from 'antd-mobile';
import PopupHeader from '@/components/PopupHeader';
import Side from '../../CenterSide';
import { useLocation } from 'react-router-dom';
import { UserCenterMenusMap } from '../../routes/children';
import { useTranslation } from 'react-i18next';

export const MobileMenu: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const name = useMemo(() => {
    try {
      const key = location.pathname.split('/').slice(-1)[0];
      return key && t(UserCenterMenusMap[key]);
    } catch (error) {
      return 'Personal Center';
    }
  }, [location.pathname, t]);
  return (
    <div className={styles.wrap}>
      <div className={styles.trigger} onClick={() => setVisible(true)}>
        {name}
        <span className="angle-icon"></span>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        position="bottom"
        bodyStyle={{
          height: '74vh',
          background: '#ffffff',
        }}
      >
        <div>
          <PopupHeader
            title="User Menu"
            color="#333"
            onClick={() => {
              setVisible(false);
            }}
          />
          <Side onClick={() => setVisible(false)} />
        </div>
      </Popup>
    </div>
  );
};

export default MobileMenu;
