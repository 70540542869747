/**
 * 活动页面基本布局
 */

import cls from 'classnames';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useThemeConfig } from '@/stores';

import bgTopImg from '@/assets/images/layout-bg-top.png';
import bgBottomImg from '@/assets/images/layout-bg-bottom.png';

import styles from './index.module.scss';

export interface ActivityLayoutProps {
  children?: React.ReactNode;
}

export const ActivityLayout: React.FC<ActivityLayoutProps> = ({ children }) => {
  const setMode = useThemeConfig((state) => state.setMode);

  useEffect(() => {
    setMode('activity');
    return () => setMode('normal');
  }, []);

  return (
    <>
      <img className={cls(styles.layoutBgTop)} src={bgTopImg} />
      <div className={cls(styles.mainContent)}>
        <Outlet />
        {children}
      </div>
      <img className={cls(styles.layoutBgBottom)} src={bgBottomImg} />
    </>
  );
};
