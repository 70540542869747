import { createRequest } from '../fetcher';

/** 获取订单列表 */
export const getOrderList = createRequest('/customer/shop/order', 'get');

/** 创建临时订单 */
export const createTempOrder = createRequest(
  '/customer/shop/temp_order',
  'post',
);

/** 获取临时订单详情 */
export const getTempOrderDetail = createRequest(
  '/customer/shop/temp_order/{order_id}',
  'get',
);

/** 获取订单状态 */
export const getOrderStatus = createRequest(
  '/customer/shop/order_status',
  'get',
);

/** 创建新订单 */
export const createOrder = createRequest('/customer/shop/order', 'post');

/** 获取订单详情 */
export const getOrderDetail = createRequest(
  '/customer/shop/order/{order_id}',
  'get',
);

/** 现金支付订单 */
export const payCashOrder = createRequest(
  '/customer/shop/order/{order_id}/pay',
  'post',
);

/** 钻石支付订单 */
export const payDiamodOrder = createRequest(
  '/customer/shop/order/{order_id}/pay_diamond',
  'post',
);

/** 支付订单定金 */
export const prePayOrder = createRequest(
  '/customer/shop/order/{order_id}/pre_pay',
  'post',
);

/** 支付订单尾款 */
export const finalPayOrder = createRequest(
  '/customer/shop/order/{order_id}/final_pay',
  'post',
);

/** 标记订单回调状态 */
export const updateOrderInfo = createRequest(
  '/customer/shop/order/{order_id}',
  'post',
);

/** 获取充值活动订单详情 */
export const getChargeOrder = createRequest(
  '/customer/pay/order/{order_id}',
  'get',
);

/** 创建物流临时订单 */
export const createLogisticsTempOrder = createRequest(
  '/customer/shop/logistics_temp_order',
  'post',
);

/** 获取物流临时订单详情 */
export const getLogisticsTempOrderDetail = createRequest(
  '/customer/shop/logistics_temp_order/{order_id}',
  'get',
);

/** 创建物流订单 */
export const createLogisticsOrder = createRequest(
  '/customer/shop/logistics_order',
  'post',
);

/** 获取物流订单详情 */
export const getLogisticsOrderDetail = createRequest(
  '/customer/shop/logistics_order/{order_id}',
  'get',
);

/** 支付物流订单 */
export const payLogisticsOrder = createRequest(
  '/customer/shop/logistics_order/{order_id}/pay',
  'post',
);

/** 获取临时购物车订单 */
export const getCartTempOrderDetail = createRequest(
  '/customer/shop/multi_temp_order/{order_id}',
  'get',
);

/** 创建购物车订单 */
export const createCartOrderDetail = createRequest(
  '/customer/shop/multi_order',
  'post',
);

/** 优惠码检查 */
export const checkCouponCode = createRequest(
  '/customer/shop/code_check',
  'post',
);

/** 获取支付手续费 */
export const getOrderPayFee = createRequest('/customer/shop/pay_fee', 'post');
