import React from 'react';
import logo from '@/assets/logo.png';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const LoginMain: React.FC<Props> = ({ children, title }) => {
  return (
    <section className={styles.loginPage}>
      <main className={styles.container}>
        <div className={styles.contentWrapper}>
          <img className={styles.logo} src={logo} alt="logo" />
          <h3 className={styles.title}>{title}</h3>
          {children}
        </div>
      </main>

      <footer className={styles.footer}>
        <p>@2023 Hexapus</p>
        <p>
          Power by Hexapus |{' '}
          <Link className={styles.link} to="/policy">
            Prvacy policy
          </Link>
        </p>
      </footer>
    </section>
  );
};

export default LoginMain;
