import React, { useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, message, Statistic } from 'antd';
import { register } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import { omit } from 'lodash';

const { Countdown } = Statistic;

const Register: React.FC = () => {
  const [query] = useUrlState<{
    redirect_url?: string;
  }>({});
  const [form] = Form.useForm();
  // const navigate = useNavigate();
  const mutate = useMutation(register);
  const [showTip, setShowTip] = useState<boolean>(false);
  const [deadline, setDeadline] = useState<number>(0);
  const onFinish = async (values: any) => {
    if (deadline > 0) {
      message.error('Send another email after 60 seconds!');
      return;
    }
    const { code } = await mutate.mutateAsync(omit(values, 'confirm'));
    if (code === 0) {
      setShowTip(true);
      setDeadline(Date.now() + 1000 * 60);
      message.success('We have sent a verification code to your email!');
      // navigate(`/login?redirect_url=${encodeURIComponent(query.redirect_url)}`);
    }
  };
  return (
    <div className={styles.register}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="nickname"
          label="Nickname"
          rules={[
            {
              required: true,
              message: 'Please input your nickname!',
              whitespace: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email!',
            },
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          tooltip="Special characters are @$!%*?&,./~;"
          rules={[
            {
              required: true,
              validator(_, value: string) {
                if (!value?.length) {
                  return Promise.reject('Please input your password!');
                }
                if (value.length! < 8) {
                  return Promise.reject('At least 8 characters');
                }
                if (value.search(/\s/) != -1) {
                  return Promise.reject('Cannot contain whitespace characters');
                }
                const re =
                  /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_])[a-zA-Z0-9\W_]+$/;
                if (!re.test(value)) {
                  return Promise.reject(
                    'At least contain letters, numbers, special characters',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item extra="We must make sure that your are a human.">
          <Button
            className={styles.btn}
            size="large"
            type="primary"
            htmlType="submit"
            loading={mutate.isLoading}
          >
            Verify
          </Button>
        </Form.Item>
      </Form>
      {!!showTip && (
        <div className={styles.tip}>
          <span>We have sent a verification link to your email.</span>
          <span className={styles.down}>
            Send another email after
            <Countdown
              format="s"
              value={deadline}
              onFinish={() => {
                setDeadline(0);
                setShowTip(false);
              }}
            />
            s
          </span>
        </div>
      )}
      <p className={styles.text}>
        Already have an account?
        <Link
          className={styles.sign}
          to={`/login?redirect_url=${encodeURIComponent(query.redirect_url)}`}
        >
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default Register;
