import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import styles from './index.module.scss';

interface Props {
  placeholder: string;
}

export const PageLoading: React.FC<Props> = ({ placeholder }): ReactElement => {
  return (
    <div className={styles.container}>
      <Spin size="large" />
      <p className={styles.placeholder}>{placeholder}</p>
    </div>
  );
};
