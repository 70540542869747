import React from 'react';
import styles from './index.module.scss';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { UserCenterMenus } from '../routes/children';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@/components/Icon';
import { useNavigate } from 'react-router-dom';

interface Props {
  onClick?: () => void;
}

export const CenterSide: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.sideWrap}>
      <h2
        className={cls(styles.userCenterTitle)}
        onClick={() => {
          navigate('/my');
          onClick?.();
        }}
      >
        <Icon id="#hxicon-my" className={styles.userCenterTitleIcon} />
        {t('center.home')}
      </h2>
      <ul className={cls(styles.userCenterMenus)}>
        {UserCenterMenus.map(([group, menus]) => (
          <li key={group}>
            <h3 className={cls(styles.userCenterMenuGroupTitle)}>{t(group)}</h3>
            <ul className={cls(styles.userCenterMenuList)}>
              {menus.map((menu) => (
                <li key={menu.name} className={cls(styles.userCenterMenu)}>
                  <Link
                    className={cls({
                      [styles.isActived]: location.pathname.includes(
                        `my/${menu.path}`,
                      ),
                    })}
                    onClick={onClick}
                    to={menu.path}
                  >
                    {t(menu.name!)}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CenterSide;
