import styles from './index.module.scss';
import cls from 'classnames';

interface Props {
  title: string;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Tag: React.FC<Props> = ({
  title,
  className,
  style,
  color = '#0EDB9F',
}) => {
  const tagStyle = { backgroundColor: color, ...style };
  return (
    <p className={cls(styles.tag, className)} style={tagStyle}>
      {title}
    </p>
  );
};

export default Tag;
