import moment, { Moment } from 'moment';
import type { SortOrder } from 'antd/es/table/interface';

/**
 * 获取排序字段值
 */
export function getOrderingValueByTableSort(sort: Record<string, SortOrder>) {
  return Object.entries(sort)
    .map(([prop, order]) => `${order === 'descend' ? '-' : ''}${prop}`)
    .join(',');
}

/**
 * 获取字段当前排序标记
 */
export function getSortOrderByOrderingValue(
  key: string,
  ordering = '',
): SortOrder | undefined {
  if (ordering === key) {
    return 'ascend';
  } else if (ordering === `-${key}`) {
    return 'descend';
  }
}

export function parseTimeRangeQuery(val?: string) {
  return val?.split(',').map((item: string) => moment(Number(item)));
}

export function formatTimeRangeQuery(val?: Moment[]) {
  return val?.map((item) => moment(item).valueOf()).join(',');
}

export function getUrlQuery(url: string = window.location.search): any {
  if (!url) return {};
  const arr = url.split('?');
  const params = arr[1].split('&');
  const obj = {};
  for (let i = 0; i < params.length; i++) {
    const param = params[i].split('=');
    obj[param[0]] = param[1];
  }
  return obj;
}

export function isInViewport(ele: HTMLElement): boolean {
  const innerHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const innerWidth = window.innerWidth || document.documentElement.clientWidth;
  const { top, left, bottom, right } = ele.getBoundingClientRect();
  if (!innerHeight || !top || !bottom) {
    return true;
  }
  const notBelow = top <= innerHeight;
  const notAbove = bottom >= 0;
  const notRight = left <= innerWidth;
  const notLeft = right >= 0;
  return notBelow && notAbove && notRight && notLeft;
}

// 获取min到max的整数，包括min、max
export const getRandomInt = (min = 0, max = 10): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * 格式化百分比值
 */
export function formatPercentage(num: number) {
  return num * 100 + '%';
}

/**
 * 计算百分比
 * @param num 分子
 * @param total 分母
 * @param fractionDigits 保留小数点数
 * @returns 格式化后的百分比值
 */
export function toPercent(num: number, total: number, fractionDigits = 2) {
  return ((num / total) * 100).toFixed(fractionDigits) + '%';
}

/**
 * 解析毫秒时间
 * @param millisecond 毫秒数
 */
export function parseTime(millisecond = 0) {
  let second = Math.floor(millisecond / 1000);
  let minute = Math.floor(second / 60);
  second = second % 60;
  const hour = Math.floor(minute / 60);
  minute = minute % 60;

  return {
    second,
    secondText: String(second).padStart(2, '0'),
    minute,
    minuteText: String(minute).padStart(2, '0'),
    hour,
    hourText: String(hour).padStart(2, '0'),
  };
}

export const requestAnimation =
  window.requestAnimationFrame ||
  function (callback: () => void) {
    return setTimeout(callback, 1000 / 60);
  };

export const cancelAnimation = (function () {
  return window.cancelAnimationFrame || clearTimeout;
})();

/**
 * 小数点后面保留第 n 位
 *
 * @param x 做近似处理的数
 * @param n 小数点后第 n 位
 * @returns 近似处理后的数
 */
export function roundFractional(x: number, n: number): number {
  return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
}

/**
 * 检查url是否含有查询字符串，如？
 * @param url string
 */
export function hasQueryString(url: string): boolean {
  const queryStringIndex = url.indexOf('?');
  if (queryStringIndex === -1) {
    return false;
  }
  const searchParams = new URLSearchParams(url.slice(queryStringIndex));
  return searchParams.toString().length > 0;
}

/**
 * 处理图片
 */
export function scaleImageUrl(url: string, size: number) {
  const src = new URL(url);
  src.searchParams.append(
    'x-oss-process',
    `image/resize,m_lfit,w_${size},h_${size}`,
  );
  return src.toString();
}

const emailRex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const testEmail = (value: string) => emailRex.test(value);

export const processHtml = (str: string) => str.replace('\n', '<br/>');
