import cls from 'classnames';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton, Checkbox, Empty } from 'antd';
import type { CheckboxProps } from 'antd';

import styles from './index.module.scss';

export interface Column<T> {
  title: React.ReactNode;
  dataIndex: string;
  render?: (item: any, record: T) => React.ReactNode;
  width?: string;
  titleAlign?: 'left' | 'right' | 'center';
  contentAlign?: 'left' | 'right' | 'center';
  verticalAlign?: React.CSSProperties['verticalAlign'];
  disabled?: boolean;
}

export interface TableRowSelection<T, KT extends keyof T> {
  selectedRowKeys?: T[KT][];
  defaultSelectedRowKeys?: T[KT][];
  getCheckboxProps?: (
    record: T,
  ) => Partial<Omit<CheckboxProps, 'checked' | 'defaultChecked'>>;
  onChange?: (selectedRowKeys: T[KT][], selectedRows: T[]) => void;
  hideSelectAll?: boolean;
  columnWidth?: string;
  columnTitle?: string | React.ReactNode;
}

export interface TableProps<T, KT extends keyof T> {
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  data?: T[];
  columns: Column<T>[];
  rowKey: KT;
  gutter?: number;
  rowHeader?: (record: T) => React.ReactNode;
  rowExpectRender?: (
    record: T,
  ) => Record<string, React.ReactNode[]> | undefined;
  footer?: React.ReactNode;
  rowSelection?: TableRowSelection<T, KT>;
  bordered?: boolean;
}

export function Table<T, KT extends keyof T>({
  style,
  className,
  data = [],
  columns,
  rowKey,
  footer,
  rowHeader,
  rowExpectRender,
  rowSelection,
  gutter = 0,
  loading = false,
  bordered = true,
}: TableProps<T, KT>) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<T[KT][]>(
    rowSelection?.defaultSelectedRowKeys ?? [],
  );
  const isEmpty = data.length === 0;

  const rowMap = useMemo(
    () => new Map(data.map((item) => [item[rowKey], item])),
    [data, rowKey],
  );

  const checkboxColumn = useMemo<Column<T>>(() => {
    const {
      hideSelectAll,
      columnTitle,
      columnWidth,
      getCheckboxProps,
      onChange,
    } = rowSelection ?? {};
    const allKeys = data
      .filter((item) => !getCheckboxProps?.(item).disabled)
      .map((item) => item[rowKey]);
    const isCheckedAll =
      selectedRowKeys.length > 0 &&
      selectedRowKeys.length === allKeys.length &&
      isEqual(Array.from(selectedRowKeys).sort(), Array.from(allKeys).sort());
    const emitChange = (allKeys: T[KT][]) =>
      onChange?.(
        allKeys,
        allKeys.map((key) => rowMap.get(key)!),
      );

    return {
      title: hideSelectAll
        ? null
        : columnTitle ?? (
            <Checkbox
              checked={isCheckedAll}
              indeterminate={!isCheckedAll && selectedRowKeys.length > 0}
              onChange={(event) => {
                const newKeys = event.target.checked ? allKeys : [];
                setSelectedRowKeys(newKeys);
                emitChange(newKeys);
              }}
            />
          ),
      dataIndex: '__checkbox',
      render: (_, record) => (
        <Checkbox
          {...getCheckboxProps?.(record)}
          checked={selectedRowKeys.includes(record[rowKey])}
          onChange={(event) => {
            let newSelectedKeys = Array.from(selectedRowKeys);
            if (event.target.checked) {
              newSelectedKeys.push(record[rowKey]);
            } else {
              newSelectedKeys = selectedRowKeys.filter(
                (key) => key !== record[rowKey],
              );
            }
            setSelectedRowKeys(newSelectedKeys);
            emitChange(newSelectedKeys);
          }}
        />
      ),
      width: columnWidth ?? '40px',
      titleAlign: 'center',
      contentAlign: 'center',
      verticalAlign: 'center',
      disabled: !rowSelection,
    };
  }, [data, rowKey, rowMap, rowSelection, selectedRowKeys]);

  const currentColumns = useMemo(
    () =>
      [checkboxColumn, ...columns].filter(
        (column) => column.disabled == null || !column.disabled,
      ),
    [columns, checkboxColumn],
  );

  const isShowHead = useMemo(
    () => currentColumns.some((column) => column.title),
    [currentColumns],
  );

  useEffect(() => {
    if (rowSelection?.selectedRowKeys) {
      setSelectedRowKeys(rowSelection?.selectedRowKeys);
    }
  }, [rowSelection?.selectedRowKeys]);

  return (
    <table
      className={cls(styles.table, className, {
        [styles.noGutter]: gutter === 0,
        [styles.bordered]: bordered,
      })}
      style={{
        ...({
          '--gutter': `${gutter}px`,
          '--row-gutter-num': rowHeader ? 2 : 1,
          '--padding-x': `20px`,
          '--padding-y': `20px`,
        } as any),
        ...style,
      }}
    >
      {isShowHead && (
        <thead>
          <tr>
            {currentColumns.map((column) => (
              <th
                key={String(column.dataIndex)}
                scope="col"
                className={cls({
                  [styles.checkboxCol]: column.dataIndex === '__checkbox',
                })}
                style={{
                  width: column.width,
                  textAlign: column.titleAlign ?? 'left',
                }}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {loading ? (
          <tr className={styles.row} style={{ top: 0 }}>
            <td colSpan={currentColumns.length}>
              <Skeleton
                active
                paragraph={{
                  width: '100%',
                  rows: 4,
                }}
                title={false}
              ></Skeleton>
            </td>
          </tr>
        ) : isEmpty ? (
          <tr className={styles.row} style={{ top: 0 }}>
            <td colSpan={currentColumns.length}>
              <Empty
                style={{ paddingTop: 0 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </td>
          </tr>
        ) : (
          data.map((item) => {
            const rowExpectContents = rowExpectRender?.(item) ?? {};
            const expectRowNum = Math.max(
              0,
              ...Object.values(rowExpectContents).map((arr) => arr.length),
            );

            return (
              <React.Fragment key={String(item[rowKey])}>
                {rowHeader && (
                  <tr className={styles.rowHeader}>
                    <td colSpan={currentColumns.length} scope="row">
                      {rowHeader(item)}
                    </td>
                  </tr>
                )}
                <tr className={styles.row}>
                  {currentColumns.map((column) => (
                    <td
                      key={String(column.dataIndex)}
                      scope="row"
                      className={cls({
                        [styles.checkboxCol]: column.dataIndex === '__checkbox',
                      })}
                      style={{
                        textAlign: column.contentAlign ?? 'left',
                        verticalAlign: column.verticalAlign ?? 'top',
                      }}
                      rowSpan={
                        rowExpectContents[column.dataIndex]?.length
                          ? 1
                          : expectRowNum + 1
                      }
                    >
                      {column.render?.(item[column.dataIndex], item) ??
                        String(item[column.dataIndex])}
                    </td>
                  ))}
                </tr>
                {Array(expectRowNum)
                  .fill(0)
                  .map((_, idx) => (
                    <tr key={idx} className={styles.row}>
                      {currentColumns.map((column) => (
                        <td
                          key={String(column.dataIndex)}
                          scope="row"
                          className={cls({
                            [styles.checkboxCol]:
                              column.dataIndex === '__checkbox',
                          })}
                          style={{
                            textAlign: column.contentAlign ?? 'left',
                            verticalAlign: column.verticalAlign ?? 'top',
                          }}
                        >
                          {column.dataIndex === '__checkbox'
                            ? null
                            : rowExpectContents[column.dataIndex]?.[idx]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </React.Fragment>
            );
          })
        )}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            <td colSpan={currentColumns.length}>{footer}</td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
