import React from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, message } from 'antd';
import { singIn } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import { useUserInfo } from '@/stores';
import { useNavigate } from 'react-router-dom';

const Email: React.FC = () => {
  const [query] = useUrlState<{
    jwt?: string;
    redirect_url?: string;
  }>({});
  const navigate = useNavigate();
  const login = useUserInfo((state) => state.login);
  const [form] = Form.useForm();
  const mutate = useMutation(singIn);
  const onFinish = async (values: any) => {
    const { data } = await mutate.mutateAsync(values);
    if (data && data.jwt) {
      message.success('Sign in Successfully!');
      login(data.jwt);
      if (query.redirect_url) {
        window.location.href = decodeURIComponent(query.redirect_url);
      } else {
        navigate('/');
      }
    }
  };
  return (
    <div className={styles.email}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="user_name"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email!',
            },
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item>
          <Link
            className={styles.pass}
            to={`/pass?type=forgot&redirect_url=${encodeURIComponent(
              query.redirect_url,
            )}`}
          >
            Forgot password?
          </Link>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password size="large" />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.btn}
            size="large"
            type="primary"
            htmlType="submit"
            loading={mutate.isLoading}
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Email;
