import create from 'zustand';
import { getUserMoney } from '@/apis';

type MoneyInfo = NonNullable<Awaited<ReturnType<typeof getUserMoney>>['data']>;

interface MoneyStore {
  moneyInfo?: MoneyInfo;
  getMoney: () => Promise<MoneyInfo | undefined | null>;
}
export const useMoneyInfo = create<MoneyStore>((set) => ({
  async getMoney() {
    try {
      const res = await getUserMoney({});
      set({
        moneyInfo: res.data,
      });
      return res.data;
    } catch {
      return null;
    }
  },
}));
