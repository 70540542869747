import React from 'react';
import useUrlState from '@ahooksjs/use-url-state';

import LoginMain from '@/components/LoginMain';
import Forgot from './Forgot';
import Register from './Register';
import Reset from './Reset';
import Setpassword from './SetPassword';

enum TypeEnum {
  // 注册
  register = 'Register',
  // 忘记密码
  forgot = 'Recover password',
  // 邮箱重置密码
  reset = 'Reset password',
  // 设置账号密码
  set = 'Set password',
}

const TypeMap = new Map<string, React.ReactNode>([
  ['register', <Register key="register" />],
  ['forgot', <Forgot key="forgot" />],
  ['reset', <Reset key="reset" />],
  ['set', <Setpassword key="set" />],
]);

const Pass: React.FC = () => {
  const [query] = useUrlState<{
    type?: TypeEnum;
    redirect_url?: string;
  }>({});
  const type = query.type || 'register';
  const title = TypeEnum[type];
  return <LoginMain title={title}>{TypeMap.get(type)}</LoginMain>;
};

export default Pass;
