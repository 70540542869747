import React from 'react';
import styles from './index.module.scss';
interface Props {
  title: string;
  children?: React.ReactNode;
}

const RowItem: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.textItem}>
      <p className={styles.title}>{title}</p>
      <div className={styles.list}>{children}</div>
    </div>
  );
};

export default RowItem;
