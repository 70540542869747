import React, { useState, useCallback, useEffect } from 'react';
import { InputNumber } from '@/components/InputNumber';
import cls from 'classnames';
import { useCarts } from '@/stores';
import { message } from 'antd';
import { debounce } from 'lodash';
import styles from './index.module.scss';

interface Props {
  passNum: number;
  max?: number;
  id: number;
  className?: string;
}
const NumChange: React.FC<Props> = ({ id, max = 1, passNum, className }) => {
  const [num, setNum] = useState(1);
  const { deleteCartList, updateCartList } = useCarts();
  const update = useCallback(
    debounce(updateCartList, 1000, { trailing: true }),
    [],
  );
  const changeNumber = (val: any) => {
    if (!!val) {
      if (val > max) {
        message.warn(`Insufficient stock, you can buy up to ${max} pieces`, 3);
        return;
      }
      setNum(val);
      update({
        goods_id: id,
        num: val,
      });
    }
  };
  useEffect(() => {
    setNum(Math.min(passNum, max));
  }, [passNum, max]);
  return (
    <div className={styles.oper}>
      <InputNumber
        value={num}
        className={cls(styles.inputNumber, className)}
        min={1}
        max={max}
        size="middle"
        onChange={changeNumber}
      />
      <span className={styles.btn} onClick={() => deleteCartList(id)}>
        Delete
      </span>
    </div>
  );
};

export default NumChange;
