import React, { useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Input, message, Col, Row, Statistic } from 'antd';
import { setAccount, sendCode } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import { omit } from 'lodash';

const { Countdown } = Statistic;

const SetPassword: React.FC = () => {
  const [query] = useUrlState<{
    redirect_url?: string;
  }>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const mutate = useMutation(setAccount);
  const [deadline, setDeadline] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (values: any) => {
    const { code } = await mutate.mutateAsync(omit(values, 'confirm'));
    if (code === 0) {
      message.success('Set password Successfully!');
      navigate(`/login?redirect_url=${encodeURIComponent(query.redirect_url)}`);
    }
  };
  const getCode = async () => {
    if (deadline > 0) {
      message.error('Send another email after 60 seconds!');
      return;
    }
    setLoading(true);
    try {
      const { code } = await sendCode({});
      setLoading(false);
      if (code === 0) {
        message.success('We have sent a verification code to your email!');
        setDeadline(Date.now() + 1000 * 60);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className={styles.set}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Password"
          name="password"
          tooltip="Special characters are @$!%*?&,./~;"
          rules={[
            {
              required: true,
              validator(_, value: string) {
                if (!value?.length) {
                  return Promise.reject('Please input your password!');
                }
                if (value.length! < 8) {
                  return Promise.reject('At least 8 characters');
                }
                if (value.search(/\s/) != -1) {
                  return Promise.reject('Cannot contain whitespace characters');
                }
                const re =
                  /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_])[a-zA-Z0-9\W_]+$/;
                if (!re.test(value)) {
                  return Promise.reject(
                    'Contain letters, numbers, special characters',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          label="Code"
          extra="We must make sure that your are a human."
        >
          <Row gutter={10}>
            <Col span={16}>
              <Form.Item
                name="code"
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'Please fill the code from your email!',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                size="large"
                className={styles.codeBtn}
                onClick={getCode}
                loading={loading}
              >
                {deadline > 0 ? (
                  <Countdown
                    className={styles.countDown}
                    format="s"
                    value={deadline}
                    onFinish={() => setDeadline(0)}
                  />
                ) : (
                  'Get Code'
                )}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.btn}
            size="large"
            type="primary"
            htmlType="submit"
            loading={mutate.isLoading}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
      <p className={styles.text}>
        Already set the password?
        <Link
          className={styles.sign}
          to={`/login?redirect_url=${encodeURIComponent(query.redirect_url)}`}
        >
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default SetPassword;
