import { createRequest } from '../fetcher';

/** 获取banner列表 */
export const getBannerList = createRequest(
  '/customer/index/banner_list',
  'get',
);

// 获取热门IPS
export const getHotIps = createRequest('/customer/index/hot_ip_list', 'get');

// 获取商品分类列表(所有分类的列表)
export const getProductKindsList = createRequest(
  '/customer/index/classification_list',
  'get',
);

// 获取单个分类详情的 商品列表
export const getProductKindInfoList = createRequest(
  '/customer/index/class_product_list',
  'get',
);

// 获取单个分类详情
export const getProductKindInfo = createRequest(
  '/customer/index/classification/{class_id}',
  'get',
);

// 获取服务说明列表
export const getServiceDescriptions = createRequest(
  '/customer/index/service_description_list',
  'get',
);

// 获取社交媒体列表
export const getSocialMedias = createRequest(
  '/customer/index/social_media_list',
  'get',
);

// 发送用户需求
export const sendUserNeed = createRequest('/customer/index/send_need', 'post');
