import React from 'react';
import ReactDOM from 'react-dom/client';
import 'whatwg-fetch';

import App from './App';
import './index.scss';
import './assets/iconfont/iconfont.js';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
